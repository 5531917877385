<template>
  <div class="vehicle-detail">
    <van-nav-bar left-arrow :title="title" @click-left="$router.back()" />
    <van-tabs v-model="activeName" animated swipeable>
      <van-tab title="基本信息">
        <base-info :info="info" custom-class="vehicle-detail-item" />
      </van-tab>
      <van-tab title="证照信息">
        <license-info :info="info" custom-class="vehicle-detail-item" />
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import { getInfoVehicle } from "@/api/psm";
import BaseInfo from "@/views/Vehicle/components/BaseInfo";
import LicenseInfo from "@/views/Vehicle/components/LicenseInfo";

export default {
  name: "VehicleDetails",
  components: { LicenseInfo, BaseInfo },
  props: ["id"],
  data() {
    return {
      title: "",
      activeName: "",
      info: {}
    };
  },
  created() {
    this.getInfo();
  },
  methods: {
    async getInfo() {
      const info = await getInfoVehicle(this.id);
      console.log(info, "info");
      this.title = `${info.vehicleCode}（${info.statusName}）`;
      this.info = info;
    }
  }
};
</script>

<style lang="scss">
.vehicle-detail {
  .vehicle-detail-item {
    height: calc(100vh - 102px);
    overflow: auto;
    .tel-click {
      color: $--color-primary;
    }
  }
}
</style>
