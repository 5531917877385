<template>
  <van-form
    label-width="25vw"
    :class="[
      'van-form__text-color',
      'wrapper-height__not-feature-btn',
      customClass
    ]"
    colon
  >
    <van-field
      :border="false"
      :value="info.vehicleCode || '--'"
      label="车牌号/编号"
    />
    <van-field
      :border="false"
      :value="info.vehicleModelName || '--'"
      label="车型"
    />
    <van-field
      :border="false"
      :value="info.vehicleTypeName || '--'"
      label="车辆类型"
    />
    <van-field
      :border="false"
      :value="info.departmentName || '--'"
      label="所属部门"
    />
    <van-field
      :border="false"
      :value="info.vehicleUseName || '--'"
      label="车辆用途"
    />
    <van-field
      :border="false"
      :value="info.contractorName || '--'"
      label="所属承包商"
    />
    <van-field :border="false" :value="info.brand || '--'" label="品牌" />
    <van-field :border="false" :value="info.color || '--'" label="颜色" />
    <van-field
      :border="false"
      :value="info.driverName || '--'"
      label="驾驶员"
    />
    <van-field :border="false" :value="info.driverPhone" label="驾驶员手机号">
      <template #input>
        <a
          v-if="info.driverPhone"
          class="tel-click"
          :href="`tel:${info.driverPhone}`"
          @click="onAClick($event)"
          >{{ info.driverPhone }}</a
        >
        <span v-else>--</span>
      </template>
    </van-field>
    <van-field
      :border="false"
      :value="info.escortName || '--'"
      label="押运员"
    />
    <van-field :border="false" :value="info.escortPhone" label="押运员手机号">
      <template #input>
        <a
          v-if="info.escortPhone"
          class="tel-click"
          :href="`tel:${info.escortPhone}`"
          @click="onAClick($event)"
          >{{ info.escortPhone }}</a
        >
        <span v-else>--</span>
      </template>
    </van-field>
  </van-form>
</template>

<script>
export default {
  name: "BaseInfo",
  props: ["info", "customClass"],
  methods: {
    onAClick(ev) {
      ev.stopPropagation();
      return false;
    }
  }
};
</script>
