<template>
  <van-form
    label-width="25vw"
    :class="[
      'van-form__text-color',
      'wrapper-height__not-feature-btn',
      'license-info',
      customClass
    ]"
    colon
  >
    <div v-for="(item, idx) in queue" :key="item.id" class="license-info-item">
      <h3 class="title">{{ item.name }}</h3>
      <van-field :border="false" :value="item.code || '--'" label="档案编号" />
      <van-field
        :border="false"
        :value="item.validDate || '--'"
        label="检验有效期"
      />
      <van-field
        v-if="idx === 2"
        :border="false"
        :value="item.vehicleCode || '--'"
        label="车牌号"
      />
      <van-field
        v-if="idx === 0 || idx === 2"
        :border="false"
        :value="item.registerDate || '--'"
        label="注册日期"
      />
      <van-field
        v-if="idx === 1 || idx === 3"
        :border="false"
        :value="item.office || '--'"
        label="核发机关"
      />
      <van-field
        :border="false"
        :value="item.certificationDate || '--'"
        label="发证日期"
      />
      <van-field
        :border="false"
        :value="item.certificationDate || '--'"
        label="电子证"
      >
        <template #input>
          <van-image
            v-if="item.fileDTO"
            fit="contain"
            :src="getFileUrlForFileSystem(item.fileDTO.id, false)"
          />
          <span v-else>--</span>
        </template>
      </van-field>
    </div>
  </van-form>
</template>

<script>
import { getFileUrlForFileSystem } from "@/utils/file";

export default {
  name: "LicenseInfo",
  props: ["info", "customClass"],
  data() {
    return { getFileUrlForFileSystem };
  },
  computed: {
    queue() {
      const arr = [];
      const info = this.info;
      arr.push(
        {
          ...info.carDrivingLicenseVO,
          name: "机动车行驶证"
        },
        {
          ...info.carTaxiLicenseVO,
          name: "车辆营运证（道路运输证）"
        },
        {
          ...info.carTrailerDrivingLicenseVO,
          name: "罐/挂车行驶证"
        },
        {
          ...info.carTrailerTaxiLicenseVO,
          name: "罐/挂车辆营运证（道路运输证）"
        }
      );
      return arr;
    }
  }
};
</script>
<style lang="scss">
.license-info {
  padding-top: 10px;
  box-sizing: border-box;
  .license-info-item {
    margin-bottom: 20px;
    .van-field {
      padding: 2px 16px;
    }
    .title {
      position: relative;
      padding-left: 16px;
      margin: 8px 0;
      font-size: 14px;
      &::after {
        content: "" !important;
        display: block !important;
        position: absolute;
        top: 50%;
        left: 10px;
        transform: translateY(-50%);
        width: 2px;
        height: 12px;
        background: #3278ff;
        border-radius: 1px;
      }
    }
  }
}
</style>
